import React, { useEffect, useState } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

const heroSocialLinks = [
  {
    name: 'Behance',
    links: 'https://www.behance.net/NexllaStudios',
  },
  {
    name: 'Linkedin',
    links: 'https://www.linkedin.com/company/nexllastudios',
  },
  {
    name: 'Instagram',
    links: 'https://www.Instagram.com/NexllaStudios',
  },
  {
    name: 'X',
    links: 'https://www.X.com/NexllaStudios',
  },
];

const funfaceData = [
  {
    title: 'Global Happy Clients',
    factNumber: '3K',
  },
  {
    title: 'Project Completed',
    factNumber: '5K',
  },
  {
    title: 'Team Members',
    factNumber: '63',
  },
  {
    title: 'Digital products',
    factNumber: '550',
  },
];

export default function Home() {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);

  pageTitle('Home | ');

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setServicesData(data.data.services.filter(service => service.isMain)); // Filter only main services if necessary
        setLoading(false);
      } catch (error) {
        console.error('Error fetching services:', error);
        setLoading(false);
      }
    };

    fetchServicesData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | Nexlla Creative Agency</title>
        <meta name="description" content="Igniting Creativity, Elevating Brands. Discover the innovative solutions and creative excellence that Nexlla Creative Agency offers to elevate your brand." />
        <meta name="keywords" content="Nexlla Creative Agency, creative solutions, branding, UI/UX design, web development, mobile app development, web3 solutions, blockchain technology, digital marketing, SEO, social media marketing, content creation, graphic design, logo design, motion graphics, video production, architecture design, sustainability consulting, AR/VR development, e-commerce solutions, digital transformation, creative technology, user experience, user interface, responsive design, front-end development, back-end development, agile methodology, project management, digital strategy, brand identity, brand strategy, customer engagement, user research, usability testing, prototyping, wireframing, interactive design, visual design, typography, illustration, photography, 3D modeling, animation, post-production, digital analytics, conversion optimization, email marketing, PPC advertising, influencer marketing, public relations, event marketing, experiential marketing, product design, packaging design, print design, environmental design, wayfinding, signage, exhibition design, interior design, landscape design, urban planning, sustainable design, green building, energy efficiency, renewable energy, smart cities, IoT, AI, machine learning, cloud computing, cybersecurity, data visualization, UI/UX trends, design thinking, customer experience, user-centered design, accessibility, inclusive design, design systems, atomic design, microinteractions, motion design, augmented reality, virtual reality, mixed reality, WebGL, 3D on the web, PWA, serverless, JAMstack, headless CMS, API-driven development, micro-frontends, design ops, agile UX, lean UX, continuous integration, continuous deployment, DevOps, quality assurance, usability engineering, information architecture, interaction design, service design, strategic design, design leadership" />
        <link rel="canonical" href="https://nexlla.com/" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "Home",
            "description": "Igniting Creativity, Elevating Brands. Discover the innovative solutions and creative excellence that Nexlla Creative Agency offers to elevate your brand.",
            "url": "https://nexlla.com/",
            "publisher": {
              "@type": "Organization",
              "name": "Nexlla Creative Agency",
              "logo": {
                "@type": "ImageObject",
                "url": "https://nexlla.com/images/logo.svg"
              }
            }
          }
          `}
        </script>
      </Helmet>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircleLoader color="#be4602" size={80} />
        </div>
      ) : (
        <>
          {/* Render the navbar here if you have one */}
          {/* Your Navbar Component */}

          {/* Start Hero Section */}
          <Hero
            title="Igniting Creativity, <br/>Elevating Brands."
            subtitle="Your Vision with Creative Excellence, Unleashing Innovation in Every Pixel and Code, Where Your Brand Meets the Future."
            btnText="Get a Quote"
            btnLink="/contact"
            scrollDownId="#service"
            socialLinksHeading=""
            heroSocialLinks={heroSocialLinks}
            bgVideoUrl="/video/NexllaBG.mp4"
          />
          {/* End Hero Section */}

          {/* Start FunFact Section */}
          <div className="container">
            <FunFact
              variant="cs-type1"
              title="Who We Are!"
              subtitle="Our journey is marked by countless success stories, where we've partnered with businesses to navigate the complexities of the digital age, ensuring they stand out."
              data={funfaceData}
            />
          </div>
          {/* End FunFact Section */}

          {/* Start Service Section */}
          <Spacing lg="150" md="80" />
          <Div id="service">
            <Div className="container">
              <Div className="row">
                <Div className="col-xl-4">
                  <SectionHeading
                    title="Services we can help you with"
                    subtitle="What Can We Do"
                    btnText="More Services From Here"
                    btnLink="/service"
                  />
                  <Spacing lg="90" md="45" />
                </Div>
                <Div className="col-xl-8">
                  <Div className="row">
                    {servicesData.map((service, index) => (
                      <React.Fragment key={service._id}>
                        {index % 4 === 0 && (
                          <>
                            <Div className="col-lg-3 col-sm-6"></Div>
                            <Div className="col-lg-3 col-sm-6">
                              <Card
                                title={service.serviceName}
                                link={`/service/${service.slug}`}
                                src={`${process.env.REACT_APP_API_URL}/api/images/${service.serviceThumbnail}`}
                                alt={service.serviceName}
                              />
                              <Spacing lg="0" md="30" />
                            </Div>
                            <Div className="col-lg-3 col-sm-6"></Div>
                            {servicesData[index + 1] && (
                              <Div className="col-lg-3 col-sm-6">
                                <Card
                                  title={servicesData[index + 1].serviceName}
                                  link={`/service/${servicesData[index + 1].slug}`}
                                  src={`${process.env.REACT_APP_API_URL}/api/images/${servicesData[index + 1].serviceThumbnail}`}
                                  alt={servicesData[index + 1].serviceName}
                                />
                                <Spacing lg="0" md="30" />
                              </Div>
                            )}
                          </>
                        )}
                        {index % 4 === 2 && (
                          <>
                            <Div className="col-lg-3 col-sm-6">
                              <Card
                                title={service.serviceName}
                                link={`/service/${service.slug}`}
                                src={`${process.env.REACT_APP_API_URL}/api/images/${service.serviceThumbnail}`}
                                alt={service.serviceName}
                              />
                              <Spacing lg="0" md="30" />
                            </Div>
                            <Div className="col-lg-3 col-sm-6"></Div>
                            {servicesData[index + 1] && (
                              <Div className="col-lg-3 col-sm-6">
                                <Card
                                  title={servicesData[index + 1].serviceName}
                                  link={`/service/${servicesData[index + 1].slug}`}
                                  src={`${process.env.REACT_APP_API_URL}/api/images/${servicesData[index + 1].serviceThumbnail}`}
                                  alt={servicesData[index + 1].serviceName}
                                />
                                <Spacing lg="0" md="30" />
                              </Div>
                            )}
                            <Div className="col-lg-3 col-sm-6"></Div>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </Div>
                </Div>
              </Div>
            </Div>
          </Div>
          {/* End Service Section */}

          {/* Start Portfolio Section */}
          <Spacing lg="150" md="50" />
          <Div>
            <Div className="container">
              <SectionHeading
                title="Portfolio To Explore"
                subtitle="Latest Projects"
                variant="cs-style1 text-center"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <PortfolioSlider category="" />
          </Div>
          {/* End Portfolio Section */}

          {/* Start Awards Section */}
          <Spacing lg="150" md="80" />
          <Div className="cs-shape_wrap_2">
            <Div className="cs-shape_2">
              <Div />
            </Div>
            <Div className="container">
              <Div className="row">
                <Div className="col-xl-4">
                  <SectionHeading
                    title="We get multiple awards"
                    subtitle="Our Awards"
                    variant="cs-style1"
                  />
                  <Spacing lg="90" md="45" />
                </Div>
                <Div className="col-xl-7 offset-xl-1">
                  <TimelineSlider />
                </Div>
              </Div>
            </Div>
          </Div>
          {/* End Awards Section */}

          {/* Start Video Block Section */}
          <Spacing lg="130" md="70" />
          <Div className="container">
            <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
              Our agile process is ability to adapt and respond to change. Agile
              organizations view change as an opportunity, not a threat.
            </h2>
            <Spacing lg="70" md="70" />
            <VideoModal
              videoSrc="https://www.youtube.com/watch?v=qXT1qzwCC0A"
              bgUrl="/images/video_bg.jpeg"
            />
          </Div>
          {/* End Video Block Section */}

          {/* Start Team Section */}
          <Spacing lg="145" md="80" />
          <Div className="container">
            <SectionHeading
              title="Awesome Team <br/>Members"
              subtitle="Our Team"
              variant="cs-style1"
            />
            <Spacing lg="85" md="45" />
            <TeamSlider />
          </Div>
          <Spacing lg="150" md="80" />
          {/* End Team Section */}

          {/* Start Testimonial Section */}
          <TestimonialSlider />
          {/* End Testimonial Section */}

          {/* Start Blog Section */}
          <Spacing lg="150" md="80" />
          <Div className="cs-shape_wrap_4">
            <Div className="cs-shape_4"></Div>
            <Div className="cs-shape_4"></Div>
            <Div className="container">
              <Div className="row">
                <Div className="col-xl-4">
                  <SectionHeading
                    title="Explore recent publication"
                    subtitle="Our Blog"
                    btnText="View More Blog"
                    btnLink="/blog"
                  />
                  <Spacing lg="90" md="45" />
                </Div>
                <Div className="col-xl-7 offset-xl-1">
                  <Div className="cs-half_of_full_width">
                    <PostSlider />
                  </Div>
                </Div>
              </Div>
            </Div>
          </Div>
          {/* End Blog Section */}

          {/* Start MovingText Section */}
          <Spacing lg="125" md="70" />
          <MovingText text="Firing Digital Creations with Leading-Edge Technologies." />
          <Spacing lg="105" md="70" />
          {/* End MovingText Section */}

          {/* Start CTA Section */}
          <Div className="container">
            <Cta
              title="Let's Discuss Making <br>Something <i>Cool</i> Together"
              btnText="Apply For Meeting"
              btnLink="/contact"
              bgSrc="/images/cta_bg.jpeg"
            />
          </Div>
          {/* End CTA Section */}
        </>
      )}
    </>
  );
}
