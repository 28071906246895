import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Team from '../Team';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function TeamPage() {
  const [teamData, setTeamData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    pageTitle('Our Team | ');
    window.scrollTo(0, 0);

    const fetchTeamData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/team/teams`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sortedTeamMembers = data.data.teamMembers.sort((a, b) => a.sortOrder - b.sortOrder);
        setTeamData(sortedTeamMembers);
      } catch (error) {
        console.error('Error fetching team data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Our Team | Nexlla Creative Agency</title>
        <meta name="description" content="Meet the talented team members of Nexlla Creative Agency who bring creativity and innovation to every project." />
        <meta name="keywords" content="Nexlla team, Nexlla employees, Nexlla staff, Nexlla workforce, Nexlla talent, Nexlla expertise, Nexlla skills, Nexlla experience, Nexlla leadership, Nexlla management, Nexlla executives, Nexlla directors, Nexlla supervisors, Nexlla managers, Nexlla department heads, Nexlla creative team, Nexlla design team, Nexlla development team, Nexlla marketing team, Nexlla sales team, Nexlla support team, Nexlla HR team, Nexlla finance team, Nexlla administration, Nexlla project managers, Nexlla designers, Nexlla developers, Nexlla programmers, Nexlla engineers, Nexlla architects, Nexlla UI/UX designers, Nexlla graphic designers, Nexlla web designers, Nexlla illustrators, Nexlla animators, Nexlla motion designers, Nexlla video editors, Nexlla 3D artists, Nexlla front-end developers, Nexlla back-end developers, Nexlla full-stack developers, Nexlla mobile app developers, Nexlla game developers, Nexlla software engineers, Nexlla QA engineers, Nexlla DevOps engineers, Nexlla system administrators, Nexlla network engineers, Nexlla data scientists, Nexlla business analysts, Nexlla digital marketers, Nexlla content writers, Nexlla copywriters, Nexlla social media specialists, Nexlla SEO experts, Nexlla PPC specialists, Nexlla email marketers, Nexlla marketing analysts, Nexlla sales representatives, Nexlla account managers, Nexlla customer success managers, Nexlla technical support, Nexlla HR managers, Nexlla recruiters, Nexlla training and development, Nexlla accountants, Nexlla financial analysts, Nexlla legal advisors, Nexlla administrative assistants, Nexlla office managers, Nexlla team collaboration, Nexlla cross-functional teams, Nexlla employee profiles, Nexlla staff directory, Nexlla organizational chart, Nexlla team building, Nexlla employee engagement, Nexlla company culture, Nexlla diversity and inclusion" />
        <link rel="canonical" href="https://nexlla.com/team" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "AboutPage",
            "name": "Our Team",
            "description": "Meet the talented team members of Nexlla Creative Agency who bring creativity and innovation to every project.",
            "url": "https://nexlla.com/team"
          }
          `}
        </script>
      </Helmet>
    
      <PageHeading
        title="Our Team"
        bgSrc="images/team_hero_bg.jpeg"
        pageLinkText="Our Team"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Meet Our Awesome <br/>Team Members"
          subtitle="Our Team"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row d-flex justify-content-center">
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={`${process.env.REACT_APP_API_URL}/api/images/${item.image}`}
                memberName={item.name}
                memberDesignation={item.position}
                memberSocial={{
                  behance: item.behance,
                  linkedin: item.linkedin,
                  twitter: item.twitter,
                  facebook: item.facebook,
                  instagram: item.instagram,
                }}
                memberUrl={`/team/${item.slug}`}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <Spacing lg="70" md="50" />
        <Div className="container">
          <Cta
            title="Let's Discuss Making <br>Something <i>Cool</i> Together"
            btnText="Apply For Meeting"
            btnLink="/contact"
            bgSrc="/images/cta_bg.jpeg"
          />
        </Div>
      </Div>
    </>
  );
}
