import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import ArchitectureService from './components/Pages/ArchitectureDesign';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import TeamDetails from './components/Pages/TeamDetails';
import Layout from './components/Layout';
import FaqPage from './components/Pages/FaqPage';
import Termsofuse from './components/Pages/Termsofuse';
import Privacypolicy from './components/Pages/Privacypolicy';
import { CircleLoader } from 'react-spinners';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Simulate a fetch call
      await new Promise(resolve => setTimeout(resolve, 2000));
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircleLoader color="#be4602" size={80} />
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="ArchitectureService" element={<ArchitectureService />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="service" element={<ServicesPage />} />
            <Route path="service/:slug" element={<ServiceDetailsPage />} />
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route path="portfolio/:slug" element={<PortfolioDetailsPage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/:slug" element={<BlogDetailsPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="team" element={<TeamPage />} />
            <Route path="team/:slug" element={<TeamDetails />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="termsofuse" element={<Termsofuse />} />
            <Route path="privacypolicy" element={<Privacypolicy />} />
          </Route>
          <Route path="/" element={<Layout headerVariant="cs-site_header_full_width" />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      )}
    </>
  );
}

export default App;
