import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';

export default function RecentPost({ title }) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const postData = await response.json();
        // The posts are already sorted by publishedAt in descending order from the server
        setPosts(postData.data.articles.slice(0, 3));
      } catch (error) {
        console.error('Error fetching recent posts:', error);
        setError('Error fetching recent posts. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentPosts();
  }, []);

  const renderPosts = isLoading ? (
    <p>Loading recent posts...</p>
  ) : error ? (
    <p>Error: {error}</p>
  ) : (
    posts.map((item, index) => (
      <li key={index}>
        <Div className="cs-recent_post">
          <Link to={`/blog/${item.slug}`} className="cs-recent_post_thumb">
            <img
              src={`${process.env.REACT_APP_API_URL}/api/images/${item.imageCover}`}
              alt={item.articleTitle}
              className="cs-recent_post_thumb_in cs-bg"
              style={{ objectFit: 'cover' }}
            />
          </Link>
          <Div className="cs-recent_post_info">
            <h3 className="cs-recent_post_title" style={{ fontSize: '14px' }}>
              <Link to={`/blog/${item.slug}`}>
                {item.articleTitle.length > 15 ? `${item.articleTitle.slice(0, 15)}...` : item.articleTitle}
              </Link>
            </h3>
            <Div className="cs-recent_post_date cs-primary_40_color">
              {new Date(item.publishedAt).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              }).replace(/ /g, '.')}
            </Div>
          </Div>
        </Div>
      </li>
    ))
  );

  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <ul className="cs-recent_posts">
        {renderPosts}
      </ul>
    </>
  );
}
