import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({ withIcon, title }) {
  const [contactData, setContactData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContactData();
  }, []);

  const fetchContactData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setContactData(data.data.contact); // Adjusted according to the backend response format
    } catch (error) {
      console.error('Error fetching contact data:', error);
      setError('Failed to fetch contact data.');
    } finally {
      setIsLoading(false); // Set loading to false once the data is fetched or an error occurs
    }
  };

  // Call fetchContactData in the appropriate lifecycle method or event handler
  useEffect(() => {
    fetchContactData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!contactData) {
    return null;
  }

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon ? <span className="cs-accent_color"></span> : ''}
          <strong>{contactData.firstCountryName}:</strong>
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="material-symbols:add-call-rounded" />
            </span>
          ) : ''}
          <a href={`tel:${contactData.firstPhoneNumber}`} className="your-phone-class">
            {contactData.firstPhoneNumber}
          </a>
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="mdi:envelope" />
            </span>
          ) : ''}
          <a href={`mailto:${contactData.firstEmail}`} className="your-email-class">
            {contactData.firstEmail}
          </a>
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="mdi:map-marker" />
            </span>
          ) : ''}
          <a
            href={contactData.firstGoogleMapsLink}
            target="_blank"
            rel="noopener noreferrer"
            className="your-map-class"
          >
            {contactData.firstAddress}
          </a>
        </li>
        {contactData.secondCountryName && (
          <>
            <li>
              {withIcon ? <span className="cs-accent_color"></span> : ''}
              <strong>{contactData.secondCountryName}:</strong>
            </li>
            <li>
              {withIcon ? (
                <span className="cs-accent_color">
                  <Icon icon="material-symbols:add-call-rounded" />
                </span>
              ) : ''}
              <a href={`tel:${contactData.secondPhoneNumber}`} className="your-phone-class">
                {contactData.secondPhoneNumber}
              </a>
            </li>
            <li>
              {withIcon ? (
                <span className="cs-accent_color">
                  <Icon icon="mdi:envelope" />
                </span>
              ) : ''}
              <a href={`mailto:${contactData.secondEmail}`} className="your-email-class">
                {contactData.secondEmail}
              </a>
            </li>
            <li>
              {withIcon ? (
                <span className="cs-accent_color">
                  <Icon icon="mdi:map-marker" />
                </span>
              ) : ''}
              <a
                href={contactData.secondGoogleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="your-map-class"
              >
                {contactData.secondAddress}
              </a>
            </li>
          </>
        )}
      </ul>
    </>
  );
}
