import React, { useState, useEffect } from 'react';
import Div from '../Div';

export default function Accordion() {
  const [faqs, setFaqs] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFaqs = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/faqs`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFaqs(data.data); // Update this line to match the API response structure
    } catch (error) {
      console.error('Error fetching FAQs:', error);
      setError('Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const handleToggle = index => {
    setSelected(selected === index ? null : index);
  };

  if (error) {
    return <Div>Error: {error}</Div>;
  }

  if (isLoading) {
    return <Div>Loading...</Div>;
  }

  return (
    <Div className="cs-accordians cs-style1">
      {Array.isArray(faqs) && faqs.length > 0 ? (
        faqs.map((item, index) => (
          <Div className={`cs-accordian ${selected === index ? 'active' : ''}`} key={index}>
            <Div className="cs-accordian_head" onClick={() => handleToggle(index)}>
              <h2 className="cs-accordian_title">{item.question}</h2>
              <span className="cs-accordian_toggle cs-accent_color">
                <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
                </svg>
              </span>
            </Div>
            <Div className="cs-accordian_body">
              <Div className="cs-accordian_body_in">{item.answer}</Div>
            </Div>
          </Div>
        ))
      ) : (
        <Div>No FAQs found.</Div>
      )}
    </Div>
  );
}