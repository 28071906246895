import React from 'react';
import { Icon } from '@iconify/react';
import './team.scss';
import Div from '../Div';

export default function Team({ memberImage, memberName, memberDesignation, memberSocial = {}, memberUrl }) {
  return (
    <Div className="cs-team cs-style1">
      <Div className="cs-member_thumb">
        <img src={memberImage} alt={memberName} />
        <Div className="cs-member_overlay" />
      </Div>
      <Div className="cs-member_info">
        <h2 className="cs-member_name">
          <a href={memberUrl}>{memberName}</a>
        </h2>
        <Div className="cs-member_designation">{memberDesignation}</Div>
      </Div>
      <Div className="cs-member_social cs-primary_color">
        {memberSocial.behance && (
          <a href={memberSocial.behance} target="_blank" rel="noopener noreferrer">
            <Icon icon="fa6-brands:behance" />
          </a>
        )}
        {memberSocial.linkedin && (
          <a href={memberSocial.linkedin} target="_blank" rel="noopener noreferrer">
            <Icon icon="fa6-brands:linkedin-in" />
          </a>
        )}
        {memberSocial.twitter && (
          <a href={memberSocial.twitter} target="_blank" rel="noopener noreferrer">
            <Icon icon="fa-brands:twitter" />
          </a>
        )}
        {memberSocial.instagram && (
          <a href={memberSocial.instagram} target="_blank" rel="noopener noreferrer">
            <Icon icon="fa-brands:instagram" />
          </a>
        )}
        {memberSocial.facebook && (
          <a href={memberSocial.facebook} target="_blank" rel="noopener noreferrer">
            <Icon icon="fa-brands:facebook-f" />
          </a>
        )}
        {memberSocial.youtube && (
          <a href={memberSocial.youtube} target="_blank" rel="noopener noreferrer">
            <Icon icon="fa-brands:youtube" />
          </a>
        )}
      </Div>
    </Div>
  );
}