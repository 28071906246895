import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function FaqPage() {
  pageTitle('FAQ | ');

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Simulating data fetching
    const fetchData = async () => {
      setIsLoading(true);
      // Simulate a delay for data fetching
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircleLoader color="#be4602" size={80} />
        </div>
      ) : (
        <>
          <Helmet>
            <title>FAQ | Nexlla Creative Agency</title>
            <meta name="description" content="Find answers to the most frequently asked questions about Nexlla Creative Agency and our services." />
            <meta name="keywords" content="Nexlla FAQ, Nexlla frequently asked questions, Nexlla common questions, Nexlla answers, Nexlla help, Nexlla support, Nexlla troubleshooting, Nexlla how-to, Nexlla services, Nexlla pricing, Nexlla payment, Nexlla refunds, Nexlla cancellations, Nexlla account management, Nexlla order tracking, Nexlla shipping, Nexlla returns, Nexlla exchanges, Nexlla warranty, Nexlla technical issues, Nexlla compatibility, Nexlla integration, Nexlla customization, Nexlla security, Nexlla privacy, Nexlla terms of use" />
            <link rel="canonical" href="https://nexlla.com/faq" />
            <script type="application/ld+json">
              {`
              {
                "@context": "http://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "Your question?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The answer to your question."
                    }
                  },
                  ...
                ]
              }
              `}
            </script>
          </Helmet>
          <PageHeading
            title="Frequently Asked Questions"
            bgSrc="/images/about_hero_bg.jpeg"
            pageLinkText="FAQ"
          />
          <Spacing lg="150" md="80" />
          <Div className="container">
            <Div className="row">
              <Div className="col-lg-4">
                <Div className="cs-faq_nav cs-radius_15">
                  <h2 className="cs-faq_nav_title cs-m0">FAQ Category</h2>
                  <Div className="cs-height_30 cs-height_lg_30" />
                  <ul className="cs-list cs-style1 cs-mp0">
                    <li>
                      <Button
                        variant="cs-type2"
                        btnLink="/faq"
                        btnText="Service Related"
                        icon={
                          <Icon icon="material-symbols:content-copy-outline-rounded" />
                        }
                      />
                    </li>
                    <li>
                      <Button
                        variant="cs-type2"
                        btnLink="/faq"
                        btnText="Pricing"
                        icon={
                          <Icon icon="material-symbols:content-copy-outline-rounded" />
                        }
                      />
                    </li>
                    <li>
                      <Button
                        variant="cs-type2"
                        btnLink="/faq"
                        btnText="Project Delivery"
                        icon={
                          <Icon icon="material-symbols:content-copy-outline-rounded" />
                        }
                      />
                    </li>
                    <li>
                      <Button
                        variant="cs-type2"
                        btnLink="/faq"
                        btnText="Documentation"
                        icon={
                          <Icon icon="material-symbols:content-copy-outline-rounded" />
                        }
                      />
                    </li>
                  </ul>
                </Div>
              </Div>
              <Div className="col-lg-7 offset-lg-1">
                <Spacing lg="0" md="40" />
                <Accordion />
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          {/* Start CTA Section */}
          <Div className="container">
            <Cta
              title="Let’s Discuss Making <br>Something <i>Cool</i> Together"
              btnText="Apply For Meeting"
              btnLink="/contact"
              bgSrc="/images/cta_bg.jpeg"
            />
          </Div>
          {/* End CTA Section */}
        </>
      )}
    </>
  );
}
