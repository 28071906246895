import React from 'react';
import { Icon } from '@iconify/react';

const Pagination = ({ totalPages, currentPage, changePage }) => {
  // Function to generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="cs-pagination_box cs-center cs-white_color cs-mp0 cs-semi_bold">
      {/* Show previous button only if current page is greater than 1 */}
      {currentPage > 1 && (
        <li>
          <button onClick={() => changePage(currentPage - 1)} className="cs-pagination_item cs-center">
            <Icon icon="akar-icons:chevron-left" />
          </button>
        </li>
      )}

      {/* Page numbers */}
      {pageNumbers.map(number => (
        <li key={number}>
          <button 
            onClick={() => changePage(number)}
            className={`cs-pagination_item cs-center ${currentPage === number ? 'active' : ''}`}
          >
            {number}
          </button>
        </li>
      ))}

      {/* Show next button only if current page is less than total pages */}
      {currentPage < totalPages && (
        <li>
        <button onClick={() => changePage(currentPage + 1)} className="cs-pagination_item cs-center">
          <Icon icon="akar-icons:chevron-right" />
        </button>
      </li>
      )}
    </ul>
  );
};

export default Pagination;