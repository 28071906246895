import React, { useState, useEffect } from 'react';
import Portfolio from '../Portfolio';
import Div from '../Div';
import Slider from 'react-slick';

export default function PortfolioSlider({ categories }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/projects`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        if (responseData.data && Array.isArray(responseData.data.projects)) {
          let projects = responseData.data.projects;
          // Filter projects based on the categories prop
          if (categories && categories.length > 0) {
            projects = projects.filter((project) => categories.includes(project.projectCategory));
          }
          // Sort projects based on publishedAt in descending order (newest first)
          const sortedProjects = projects.sort((a, b) => b.sortOrder - a.sortOrder);
          setData(sortedProjects);
        } else {
          console.error('Invalid response format:', responseData);
          setData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [categories]);
  

  /** Slider Settings **/
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-slider cs-style3 cs-gap-24">
      {data.map((item, index) => (
        <Div key={index}>
          <Portfolio
            title={item.projectTitle}
            subtitle="See Details"
            href={`/portfolio/${item.slug}`}
            src={`${process.env.REACT_APP_API_URL}/api/images/${item.projectCover}`}
          />
        </Div>
      ))}
    </Slider>
  );
}