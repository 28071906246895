import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function ContactPage() {
  useEffect(() => {
    pageTitle('Contact Us | ');
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    projectType: '',
    mobile: '',
    message: '',
  });
  const [submitStatus, setSubmitStatus] = useState('Send Message'); // Initial button text
  const [mainGoogleMapsLink, setMainGoogleMapsLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchMainGoogleMapsLink();
  }, []);

  const fetchMainGoogleMapsLink = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMainGoogleMapsLink(data.data.contact.mainGoogleMapsLink); // Assuming the API response has the same structure as ContactInfoWidget
    } catch (error) {
      console.error('Error fetching main Google Maps link:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitStatus('Submitting...'); // Update button text to indicate submission

    try {
      const response = await fetch('https://formspree.io/f/mgegnqqr', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitStatus('Submitted successfully!');
        // Optionally, clear the form fields
        setFormData({
          fullName: '',
          email: '',
          projectType: '',
          mobile: '',
          message: '',
        });
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error(error);
      setSubmitStatus('Error submitting form, please try again.');
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Contact Us | Nexlla Creative Agency</title>
        <meta name="description" content="Get in touch with Nexlla Creative Agency for your next project. We'd love to hear from you!" />
        <meta name="keywords" content="Nexlla contact, Nexlla contact information, Nexlla contact details, Nexlla contact us, Nexlla get in touch, Nexlla inquiries, Nexlla support, Nexlla customer service, Nexlla feedback, Nexlla office location, Nexlla address, Nexlla phone number, Nexlla email, Nexlla contact form, Nexlla social media, Nexlla Facebook, Nexlla Twitter, Nexlla Instagram, Nexlla LinkedIn, Nexlla YouTube, Nexlla business hours, Nexlla availability, Nexlla response time, Nexlla map, Nexlla directions" />
        <link rel="canonical" href="https://nexlla.com/contact" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "ContactPage",
            "name": "Contact Us",
            "description": "Get in touch with Nexlla Creative Agency for your next project. We'd love to hear from you!",
            "url": "https://nexlla.com/contact"
          }
          `}
        </script>
      </Helmet>

      <PageHeading title="Contact Us" bgSrc="/images/contact_hero_bg.jpeg" pageLinkText="Contact" />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading title="Do you have a project in your mind?" subtitle="Getting in Touch" />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form
              className="row"
              action="https://formspree.io/f/mgegnqqr"
              method="POST"
              onSubmit={handleSubmit}
            >
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" name="projectType" value={formData.projectType} onChange={handleChange} className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Your Message*</label>
                <textarea name="message" value={formData.message} onChange={handleChange} cols="30" rows="7" className="cs-form_field" required></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1">
                  {submitStatus}
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src={mainGoogleMapsLink}
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
