import React, { useEffect, useState } from 'react';
import Div from '../Div';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import Newsletter from '../Widget/Newsletter';
import SocialWidget from '../Widget/SocialWidget';
import TextWidget from '../Widget/TextWidget';
import './footer.scss';
const copyrightLinks = [
  {
    title: 'FAQ',
    href: '/faq',
  },
  {
    title: 'Terms of Use',
    href: '/termsofuse',
  },
  {
    title: 'Privacy Policy',
    href: '/privacypolicy',
  },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    // Fetch service data
    const fetchServicesData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setServicesData(data.data.services.filter(service => !service.isMain));
      } catch (error) {
        console.error('Error fetching services data:', error);
      }
    };

    fetchServicesData();
  }, []);

  const handleServiceClick = (e) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.location.href = href; // Navigate to the service page
  };

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/footer_logo.svg"
                  logoAlt="Nexlla Creative Agency Logo"
                  text="Where Ideas Flourish, Crafting Digital Experiences That Resonate, Innovation, Creativity, and Strategy at Your Fingertips, Building Tomorrow's Legacy, Today, Your Success, Our Commitment."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
              <MenuWidget
                  menuItems={[
                    ...servicesData.map((service) => ({
                      title: service.serviceName,
                      href: `/service/${service.slug}`,
                      onClick: (e) => handleServiceClick(e, service.serviceName),
                    })),
                    {
                      title: 'Construction Engineering',
                      href: '/ArchitectureService',
                      onClick: handleServiceClick,
                    },
                  ]}
                  menuHeading="Our Services"
                />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Details" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter
                  title="Subscribe"
                  subtitle="Stay Ahead with Nexlla, Exclusive Insights and Inspirations, Direct to Your Inbox."
                  placeholder="Your Email"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 Nexlla Creative Agency.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
