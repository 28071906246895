import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

const funfaceData = [
  {
    title: 'Global Happy Clients',
    factNumber: '3K',
  },
  {
    title: 'Project Completed',
    factNumber: '5K',
  },
  {
    title: 'Team Members',
    factNumber: '63',
  },
  {
    title: 'Digital products',
    factNumber: '550',
  },
];

export default function AboutPage() {
  pageTitle('About | ');

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Simulate a data fetch for the page
    const fetchData = async () => {
      try {
        // Simulating a fetch call
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoading(false);
      } catch (error) {
        setError('An error occurred while fetching data.');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>About Us | Nexlla Creative Agency</title>
        <meta name="description" content="Learn more about Nexlla Creative Agency, our mission, values, and the team that makes it all happen." />
        <meta name="keywords" content="Nexlla about us, Nexlla company profile, Nexlla history, Nexlla mission, Nexlla vision, Nexlla values, Nexlla team, Nexlla expertise, Nexlla services, Nexlla portfolio, Nexlla achievements, Nexlla awards, Nexlla partnerships, Nexlla clients, Nexlla testimonials, Nexlla case studies, Nexlla approach, Nexlla methodology, Nexlla process, Nexlla quality assurance, Nexlla client satisfaction, Nexlla corporate social responsibility, Nexlla sustainability, Nexlla community engagement, Nexlla diversity and inclusion, Nexlla company culture, Nexlla work environment, Nexlla employee benefits, Nexlla career opportunities, Nexlla internships, Nexlla professional development, Nexlla leadership team, Nexlla executives, Nexlla board of directors, Nexlla advisors, Nexlla industry experts, Nexlla thought leadership, Nexlla publications, Nexlla speaking engagements, Nexlla media coverage, Nexlla press releases, Nexlla contact information, Nexlla office locations, Nexlla headquarters, Nexlla global presence, Nexlla subsidiaries, Nexlla affiliates, Nexlla partnerships, Nexlla collaborations, Nexlla industry associations, Nexlla certifications, Nexlla accreditations, Nexlla ISO standards, Nexlla quality management system, Nexlla environmental policy, Nexlla social responsibility initiatives, Nexlla community outreach programs, Nexlla charitable contributions, Nexlla volunteering" />
        <link rel="canonical" href="https://nexlla.com/about" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "AboutPage",
            "name": "About Us",
            "description": "Learn more about Nexlla Creative Agency, our mission, values, and the team that makes it all happen.",
            "url": "https://nexlla.com/about"
          }
          `}
        </script>
      </Helmet>
      
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Igniting Creativity, Elevating Brands."
              subtitle="About Nexlla Creative Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Welcome to Nexlla, a beacon of innovation in the creative and technological realms. 
              Founded with a vision to revolutionize the way businesses interact with the digital world, 
              we are a full-service creative agency that believes in the power of design, technology, 
              and strategy to transform ideas into extraordinary successes.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
              Welcome to the forefront of the future. Welcome to Nexlla.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Who We Are!"
          subtitle="Our journey is marked by countless success stories, where we've partnered with businesses to navigate the complexities of the digital age, ensuring they stand out."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Highly experienced pepole with us"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              At Nexlla, our unmatched expertise spans a diverse range of disciplines, 
              offering unparalleled proficiency in digital, creative, and strategic realms. 
              We craft tailored solutions, understanding that each business's uniqueness demands a personalized approach, 
              ensuring goals and challenges are met with precision. 
              Our commitment to cutting-edge innovation means staying ahead with Web3 development and immersive designs, 
              bringing tomorrow’s technology to today's projects. Our holistic approach covers you from concept to execution, 
              ensuring brand cohesion and synergy. With a proven track record of propelling clients to new heights, 
              we prioritize partnership, transparency, and mutual respect. We're dedicated to sustainable growth, 
              designing strategies for longevity and success. Partner with Nexlla, where your vision is nurtured into a lasting legacy.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s Discuss Making <br>Something <i>Cool</i> Together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
