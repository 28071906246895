import React, { useState, useEffect } from 'react';
import PricingTable from './index'; // Assuming PricingTable is in the same directory
import Section from '../Div';
import Spacing from '../Spacing';

export default function PricingTableList() {
  const [tab, setTab] = useState('monthly');
  const [selectedCurrency, setSelectedCurrency] = useState('USD'); // Default currency
  const [pricingData, setPricingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    fetchGlobalSettings();
    fetchPricingData();
  }, []);

  const fetchGlobalSettings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/globalsettings/manage-global-settings`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setGlobalSettings(data.data.globalSettings);
    } catch (error) {
      console.error('Error fetching global settings:', error);
    }
  };

  const fetchPricingData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/pricing/pricing`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPricingData(data.data.pricingData);
    } catch (error) {
      console.error('Error fetching pricing data:', error);
      setError('Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const currencySymbols = {
    USD: '$ ',
    AED: 'AED ',
    OMR: 'OMR ',
  };

  const handleSetCurrency = (currency) => {
    setSelectedCurrency(currency);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat().format(price);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!globalSettings) {
    return <div>Loading global settings...</div>;
  }

  const enabledCurrencies = Object.entries(globalSettings.multiCurrency.currencies)
    .filter(([_, enabled]) => enabled)
    .map(([currency]) => currency);

  return (
    <Section className="position-relative">
      <div className="tabs-container">
        <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
          <li className={tab === 'monthly' ? 'active' : ''} onClick={() => setTab('monthly')}>
            Monthly
          </li>
          <li className={tab === 'yearly' ? 'active' : ''} onClick={() => setTab('yearly')}>
            Yearly
          </li>
        </ul>
        {globalSettings.multiCurrency.enabled && (
          <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font currency-tabs">
            {enabledCurrencies.map((currency) => (
              <li
                key={currency}
                className={currency === selectedCurrency ? 'active' : ''}
                onClick={() => handleSetCurrency(currency)}
              >
                {currency}
              </li>
            ))}
          </ul>
        )}
      </div>
      <Section className="row">
        {pricingData.map((pricing) => {
          const filteredPricing = {
            ...pricing,
            pricing: {
              ...pricing.pricing,
              [tab]: Object.fromEntries(
                Object.entries(pricing.pricing[tab]).filter(([currency]) =>
                  enabledCurrencies.includes(currency)
                )
              ),
            },
          };

          return (
            <Section key={pricing._id} className="col-lg-4">
              <PricingTable
                title={filteredPricing.package}
                price={formatPrice(filteredPricing.pricing[tab][selectedCurrency])}
                currency={currencySymbols[selectedCurrency]}
                timeline={tab}
                features={filteredPricing.features}
                btnText={filteredPricing.btnText}
                btnLink={filteredPricing.btnLink.replace('{tab}', tab)}
              />
              <Spacing lg="25" md="25" />
            </Section>
          );
        })}
      </Section>
    </Section>
  );
}