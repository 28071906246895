import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Card from '../Card';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import PricingTableList from '../PricingTable/PricingTableList';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Spacing from '../Spacing';
import MovingText2 from '../MovingText/MovingText2';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function ServicesPage() {
  pageTitle('Services | ');

  // State to hold the fetched service data
  const [servicesData, setServicesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServicesData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredServices = data.data.services.filter(service => !service.isMain);
        const sortedServices = filteredServices.sort((a, b) => b.sortOrder - a.sortOrder);
        setServicesData(sortedServices);
      } catch (error) {
        console.error('Error fetching services data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchServicesData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Split the services data into two arrays for moving text sections
  const serviceData1 = servicesData.slice(0, Math.ceil(servicesData.length / 2));
  const serviceData2 = servicesData.slice(Math.ceil(servicesData.length / 2));

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Services | Nexlla Creative Agency</title>
        <meta name="description" content="Discover our range of services including UI/UX Design, Web Development, Mobile App Development, and Web3 Solutions." />
        <meta name="keywords" content="Nexlla services, creative agency services, UI/UX design services, web design services, web development services, front-end development, back-end development, full-stack development, mobile app design, mobile app development, iOS app development, Android app development, cross-platform app development, responsive web design, e-commerce development, content management systems, web application development, progressive web apps, website maintenance, website optimization, web3 solutions, blockchain development, smart contract development, decentralized applications, NFT marketplace development, cryptocurrency integration, graphic design services, logo design, brand identity design, marketing collateral design, packaging design, illustration, motion graphics, video production, 2D animation, 3D animation, AR/VR development, virtual reality experiences, augmented reality applications, game development, interactive installations, digital marketing services, SEO, PPC advertising, social media marketing, content marketing, email marketing, marketing automation, analytics and tracking, conversion rate optimization, branding services, brand strategy, brand positioning, brand guidelines, brand messaging, marketing strategy, digital transformation, cloud migration, legacy system modernization, IT consulting, software development, custom software solutions, CRM integration, ERP implementation, data analytics, machine learning, artificial intelligence, UX research, user testing, usability evaluation, information architecture, interaction design, user flow optimization, design thinking, agile development, scrum methodology, project management, quality assurance, deployment and hosting" />
        <link rel="canonical" href="https://nexlla.com/service" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Service",
            "name": "Services",
            "description": "Discover our range of services including UI/UX Design, Web Development, Mobile App Development, and Web3 Solutions.",
            "url": "https://nexlla.com/service"
          }
          `}
        </script>
      </Helmet>
      <PageHeading
        title="Services"
        bgSrc="images/service_hero_bg.jpeg"
        pageLinkText="Services"
      />
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                {servicesData.map((service, index) => (
                  <React.Fragment key={index}>
                    {index % 4 === 0 && (
                      <>
                        <Div className="col-lg-3 col-sm-6"></Div>
                        <Div className="col-lg-3 col-sm-6">
                          <Card
                            title={service.serviceName}
                            link={`/service/${service.slug}`}
                            src={`${process.env.REACT_APP_API_URL}/api/images/${service.serviceThumbnail}`}
                            alt={service.title}
                          />
                          <Spacing lg="0" md="30" />
                        </Div>
                        <Div className="col-lg-3 col-sm-6"></Div>
                        <Div className="col-lg-3 col-sm-6">
                          {servicesData[index + 1] && (
                            <>
                              <Card
                                title={servicesData[index + 1].serviceName}
                                link={`/service/${servicesData[index + 1].slug}`}
                                src={`${process.env.REACT_APP_API_URL}/api/images/${servicesData[index + 1].serviceThumbnail}`}
                                alt={service.title}
                              />
                              <Spacing lg="0" md="30" />
                            </>
                          )}
                        </Div>
                      </>
                    )}
                    {index % 4 === 2 && (
                      <>
                        <Div className="col-lg-3 col-sm-6">
                          <Card
                            title={service.serviceName}
                            link={`/service/${service.slug}`}
                            src={`${process.env.REACT_APP_API_URL}/api/images/${service.serviceThumbnail}`}
                            alt={service.title}
                          />
                          <Spacing lg="0" md="30" />
                        </Div>
                        <Div className="col-lg-3 col-sm-6"></Div>
                        <Div className="col-lg-3 col-sm-6">
                          {servicesData[index + 1] && (
                            <>
                              <Card
                                title={servicesData[index + 1].serviceName}
                                link={`/service/${servicesData[index + 1].slug}`}
                                src={`${process.env.REACT_APP_API_URL}/api/images/${servicesData[index + 1].serviceThumbnail}`}
                                alt={service.title}
                              />
                              <Spacing lg="0" md="30" />
                            </>
                          )}
                        </Div>
                        <Div className="col-lg-3 col-sm-6"></Div>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      {/* Start Moving Text Section */}
      <Spacing lg="150" md="80" />
      <MovingText2
        data={serviceData1.map((service) => ({
          title: service.serviceName,
          href: `/service/${service.slug}`,
        }))}
      />
      <Spacing lg="20" md="10" />
      <MovingText2
        reverseDirection
        data={serviceData2.map((service) => ({
          title: service.serviceName,
          href: `/service/${service.slug}`,
        }))}
      />
      {/* End Moving Text Section */}

      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />
        <PricingTableList />
      </Div>
      <Spacing lg="125" md="55" />
      <TestimonialSlider />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let's Discuss Making <br>Something <i>Cool</i> Together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
