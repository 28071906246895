import React, { useState } from 'react';
import Div from '../Div';

export default function Newsletter({ title, subtitle, placeholder }) {
  const [email, setEmail] = useState('');
  const [submitStatus, setSubmitStatus] = useState('Subscribe'); // Initial button text

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  // This function now simulates handling Formspree without leaving the page
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitStatus('Submitting...');

    try {
      const response = await fetch('https://formspree.io/f/xyyrnvaw', {
        method: 'POST',
        body: JSON.stringify({ email: email }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitStatus('Subscribed successfully!');
        setEmail(''); // Clear email input
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus('Error, try again');
    }
  };

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form className="cs-newsletter_form" onSubmit={handleSubmit}>
          <input type="email" className="cs-newsletter_input" placeholder={placeholder || 'Your Email'} name="email" value={email} onChange={handleChange} required />
          <button type="submit" className="cs-newsletter_btn">{submitStatus}</button>
        </form>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  );
}