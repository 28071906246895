import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Pagination from '../Pagination';
import PostStyle2 from '../Post/PostStyle2';
import Div from '../Div';
import Sidebar from '../Sidebar.jsx';
import Spacing from '../Spacing';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function BlogPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('');
  const postsPerPage = 3;
  const location = useLocation();
  const navigate = useNavigate();
  const recentPosts = []; // Placeholder, remove if you fetch recent posts
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Sort the posts by published date in descending order
        setPosts(data.data.articles);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    pageTitle("Blog | ");

    const searchParams = new URLSearchParams(location.search);
    const initialFilter = searchParams.get('filter') || '';
    setFilter(initialFilter);
  }, [location]);

  // Filter posts based on the current filter
  const filteredPosts = posts.filter(post => !filter || post.category.toLowerCase().includes(filter.toLowerCase()));

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  // Calculate the posts to be shown on the current page
  const currentPosts = filteredPosts.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  const handleFilterChange = (newFilter) => {
    navigate(`/blog?filter=${newFilter}`);
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet>
        <title>Blog | Nexlla Creative Agency</title>
        <meta name="description" content="Read the latest articles and insights from Nexlla Creative Agency." />
        <meta name="keywords" content="Nexlla blog, creative agency blog, design blog, development blog, web design articles, UI/UX design tips, front-end development tutorials, back-end development guides, mobile app design trends, web development best practices, responsive web design techniques, e-commerce optimization, web3 insights, blockchain technology articles, NFT market analysis, cryptocurrency news, graphic design inspiration, logo design showcase, branding case studies, digital marketing strategies, SEO tips, PPC advertising guides, social media marketing tactics, content marketing examples, email marketing best practices, marketing automation tools, analytics and tracking guides, conversion rate optimization techniques, user experience research, user testing methods, usability evaluation insights, information architecture principles, interaction design patterns, design thinking articles, agile development methodologies, project management tips, quality assurance best practices, software development trends, IT consulting advice, technology industry news" />
        <link rel="canonical" href="https://nexlla.com/blog" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Blog",
            "name": "Nexlla Blog",
            "description": "Read the latest articles and insights from Nexlla Creative Agency.",
            "url": "https://nexlla.com/blog"
          }
          `}
        </script>
      </Helmet>
      <PageHeading title="Our Blog" bgSrc="/images/blog_hero_bg.jpeg" pageLinkText="Blog" />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">
            {isLoading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircleLoader color="#be4602" size={80} />
              </div>
            )}
            {error && <div>Error: {error}</div>}
            {!isLoading && !error && currentPosts.map((item, index) => (
              <Div key={index}>
                <PostStyle2
                  thumb={`${process.env.REACT_APP_API_URL}/api/images/${item.imageCover}`}
                  thumbAlt={item.articleTitle}
                  title={item.articleTitle}
                  subtitle={
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          item.articleContent.substring(0, 200) +
                          (item.articleContent.length > 200 ? '...' : ''),
                      }}
                    />
                  }
                  date={new Date(item.publishedAt)
                    .toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })
                    .replace(/ /g, '.')}
                  category={item.category}
                  categoryHref={`/blog?filter=${item.category}`}
                  href={`/blog/${item.slug}`}
                />
                {currentPosts.length > index + 1 && <Spacing lg="95" md="60" />}
              </Div>
            ))}
            <Spacing lg="60" md="40" />
            <Pagination totalPages={totalPages} currentPage={currentPage} changePage={changePage} />
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
            <Sidebar onFilterChange={handleFilterChange} currentFilter={filter} recentPosts={recentPosts} data={posts} />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let's Discuss Making <br>Something <i>Cool</i> Together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
