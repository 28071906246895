// TermsOfUsePage.jsx
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Div from '../Div';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function TermsOfUsePage() {
  const [termsOfUse, setTermsOfUse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  pageTitle('Terms of Use | ');

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchTermsOfUse = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/terms`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTermsOfUse(data.data.termsOfUse);
      } catch (error) {
        console.error('Error fetching terms of use data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchTermsOfUse();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!termsOfUse) {
    return null;
  }

  const renderContentWithLinks = (content) => {
    // Regular expression to identify links in content
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
    return content.replace(
      linkRegex,
      '<a style="text-decoration: underline;" href="$1" target="_blank" rel="noopener noreferrer"'
    );
  };

  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Nexlla Creative Agency</title>
        <meta name="description" content="Read the terms and conditions of using the Nexlla Creative Agency website and services." />
        <meta name="keywords" content="Nexlla terms of use, Nexlla terms and conditions, Nexlla terms of service, Nexlla user agreement, Nexlla legal agreement, Nexlla website usage, Nexlla acceptable use, Nexlla prohibited activities, Nexlla user responsibilities, Nexlla intellectual property, Nexlla copyright, Nexlla trademarks, Nexlla user-generated content, Nexlla content ownership, Nexlla liability disclaimer, Nexlla warranty disclaimer, Nexlla indemnification, Nexlla termination, Nexlla modifications, Nexlla governing law, Nexlla dispute resolution" />
        <link rel="canonical" href="https://nexlla.com/termsofuse" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "Terms and Conditions",
            "description": "Read the terms and conditions of using the Nexlla Creative Agency website and services.",
            "url": "https://nexlla.com/termsofuse"
          }
          `}
        </script>
      </Helmet>
      <PageHeading title="Terms of Use" bgSrc="/images/about_hero_bg.jpeg" pageLinkText="Terms of Use" />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-12">
            <Div className="terms-content" dangerouslySetInnerHTML={{ __html: renderContentWithLinks(termsOfUse) }} />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
    </>
  );
}
