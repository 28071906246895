import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import Spacing from '../Spacing';
import SocialWidget from '../Widget/TeamSocialWidget';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function TeamDetails() {
  const { slug } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    pageTitle('Team Member Details | ');
    window.scrollTo(0, 0);

    const fetchMemberData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/team/teams/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMemberData(data.data.teamMember);
      } catch (error) {
        console.error('Error fetching team member data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMemberData();
  }, [slug]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!memberData) {
    return <div>Team member not found.</div>;
  }

  // Replace newline characters (\n) with <br> tags
  const bioWithLineBreaks = memberData.bio.replace(/\n/g, '<br>');

  return (
    <>
      <Helmet>
        <title>{memberData ? `${memberData.name} | Nexlla Creative Agency` : 'Team Member Details | Nexlla Creative Agency'}</title>
        <meta name="description" content={memberData ? `${memberData.name} - ${memberData.position}. Learn more about their role and contributions at Nexlla Creative Agency.` : 'Meet our team members at Nexlla Creative Agency.'} />
        <meta name="keywords" content={memberData ? `${memberData.name}, ${memberData.position}, Nexlla Creative Agency, team member` : 'team member, Nexlla Creative Agency, team'} />
        <link rel="canonical" href={`https://nexlla.com/team/${slug}`} />
        {memberData && (
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "Person",
              "name": "${memberData.name}",
              "jobTitle": "${memberData.position}",
              "description": "${memberData.bio}",
              "url": "https://nexlla.com/team/${slug}",
              "image": "${process.env.REACT_APP_API_URL}/api/images/${memberData.image}",
              "sameAs": [
                "${memberData.linkedin}",
                "${memberData.twitter}",
                "${memberData.facebook}",
                "${memberData.instagram}",
                "${memberData.behance}"
              ]
            }
            `}
          </script>
        )}
      </Helmet>
    
      <PageHeading
        title="Team Member Details"
        bgSrc="/images/team_hero_bg.jpeg"
        pageLinkText={memberData.name}
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src={`${process.env.REACT_APP_API_URL}/api/images/${memberData.image}`}
                alt={memberData.name}
                className="w-100"
              />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg="0" md="45" />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title">{memberData.name}</h2>
              <Div className="cs-height_10 cs-height_lg_10" />
              <h3 className="cs-section_subtitle">{memberData.position}</h3>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              {/* Use dangerouslySetInnerHTML to render the bio with line breaks */}
              <p className="cs-m0 text-justified" dangerouslySetInnerHTML={{ __html: bioWithLineBreaks }} />
              <Div className="cs-height_45 cs-height_lg_30" />
              <SocialWidget
                behance={memberData.behance}
                linkedin={memberData.linkedin}
                twitter={memberData.twitter}
                facebook={memberData.facebook}
                instagram={memberData.instagram}
              />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let's Discuss Making <br>Something <i>Cool</i> Together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
