// PrivacyPolicyPage.jsx
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Div from '../Div';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function PrivacyPolicyPage() {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  pageTitle('Privacy Policy | ');

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPrivacyPolicy = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/privacy`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPrivacyPolicy(data.data.privacyPolicy);
      } catch (error) {
        console.error('Error fetching privacy policy data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPrivacyPolicy();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!privacyPolicy) {
    return null;
  }

  const renderContentWithLinks = (content) => {
    // Regular expression to identify links in content
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
    return content.replace(
      linkRegex,
      '<a style="text-decoration: underline;" href="$1" target="_blank" rel="noopener noreferrer"'
    );
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Nexlla Creative Agency</title>
        <meta name="description" content="Read the privacy policy of Nexlla Creative Agency to understand how we handle your personal information." />
        <meta name="keywords" content="Nexlla privacy policy, Nexlla data privacy, Nexlla information security, Nexlla personal data, Nexlla data collection, Nexlla data usage, Nexlla data sharing, Nexlla data protection, Nexlla data retention, Nexlla cookies, Nexlla tracking, Nexlla privacy rights, Nexlla data access, Nexlla data correction, Nexlla data deletion, Nexlla data portability, Nexlla privacy complaints, Nexlla privacy contact, Nexlla GDPR compliance, Nexlla CCPA compliance, Nexlla privacy laws, Nexlla privacy regulations, Nexlla privacy best practices" />
        <link rel="canonical" href="https://nexlla.com/privacypolicy" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "Privacy Policy",
            "description": "Read the privacy policy of Nexlla Creative Agency to understand how we handle your personal information.",
            "url": "https://nexlla.com/privacypolicy"
          }
          `}
        </script>
      </Helmet>
      <PageHeading title="Privacy Policy" bgSrc="/images/about_hero_bg.jpeg" pageLinkText="Privacy Policy" />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-12">
            <Div className="privacy-content" dangerouslySetInnerHTML={{ __html: renderContentWithLinks(privacyPolicy) }} />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
    </>
  );
}
