import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget({ behance, linkedin, instagram, twitter }) {
  return (
    <Div className="cs-social_btns cs-style1">
      {behance ? (
        <Link to={behance} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:behance" />
        </Link>
      ) : (
        <Link to="https://www.behance.net/NexllaStudios" className="cs-center">
          <Icon icon="fa6-brands:behance" />
        </Link>
      )}
      {linkedin ? (
        <Link to={linkedin} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:linkedin-in" />
        </Link>
      ) : (
        <Link to="https://www.linkedin.com/company/nexllastudios" className="cs-center">
          <Icon icon="fa6-brands:linkedin-in" />
        </Link>
      )}
      {instagram ? (
        <Link to={instagram} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:instagram" />
        </Link>
      ) : (
        <Link to="https://www.Instagram.com/NexllaStudios" className="cs-center">
          <Icon icon="fa6-brands:instagram" />
        </Link>
      )}
      {twitter ? (
        <Link to={twitter} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:twitter" />
        </Link>
      ) : (
        <Link to="https://www.X.com/NexllaStudios" className="cs-center">
          <Icon icon="fa6-brands:twitter" />
        </Link>
      )}
    </Div>
  );
}