import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function PortfolioDetailsPage() {
  const { slug } = useParams();
  const [projectDetails, setProjectDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options).replace(/ /g, '.');
  };

  const formatSoftware = (softwareArray) => {
    if (Array.isArray(softwareArray)) {
      return softwareArray.join(', ');
    }
    return softwareArray;
  };

  useEffect(() => {
    const fetchProjectDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/projects/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const project = data.data.project;

        // Format the date and software
        const formattedProject = {
          ...project,
          projectDated: formatDate(project.projectDated),
          projectSoftware: formatSoftware(project.projectSoftware),
        };

        setProjectDetails(formattedProject);

        // Set the page title
        if (formattedProject) {
          pageTitle(`${formattedProject.projectTitle} | `);
        }
      } catch (error) {
        console.error('Error fetching project details:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectDetails();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {projectDetails && (
        <Helmet>
          <title>{`${projectDetails.projectTitle} | Nexlla Creative Agency`}</title>
          <meta name="description" content={projectDetails.projectDescription} />
          <meta name="keywords" content={projectDetails.keywords?.join(', ')} />
          <link rel="canonical" href={`https://nexlla.com/portfolio/${slug}`} />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "CreativeWork",
              "name": projectDetails.projectTitle,
              "description": projectDetails.projectDescription,
              "url": `https://nexlla.com/portfolio/${slug}`,
              "image": `${process.env.REACT_APP_API_URL}/api/images/${projectDetails.projectCover}`,
              "datePublished": projectDetails.projectDated,
              "author": {
                "@type": "Organization",
                "name": "Nexlla Creative Agency"
              },
              "about": {
                "@type": "Thing",
                "name": projectDetails.projectCategory
              }
            })}
          </script>
        </Helmet>
      )}

      <PageHeading
        title="Portfolio Details"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={slug}
      />
      <Spacing lg="150" md="80" />
      {projectDetails && (
        <Div className="container">
          <img src={`${process.env.REACT_APP_API_URL}/api/images/${projectDetails.projectCover}`} alt="Details" className="cs-radius_15 w-100" />
          <Spacing lg="90" md="40" />
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                title={projectDetails.projectTitle}
                subtitle={projectDetails.projectDescription}
              >
                <Spacing lg="40" md="20" />
                <p className="cs-m0 text-justified" dangerouslySetInnerHTML={{ __html: projectDetails.projectContent }} />
              </SectionHeading>
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <Spacing lg="60" md="40" />
              <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info:</h2>
              <Spacing lg="50" md="30" />
              <Div className="row">
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">Category:</h3>
                  <p className="cs-m0">{projectDetails.projectCategory}</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">Location:</h3>
                  <p className="cs-m0">{projectDetails.projectLocation}</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">Software:</h3>
                  <p className="cs-m0">{projectDetails.projectSoftware}</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">Dated:</h3>
                  <p className="cs-m0">{projectDetails.projectDated}</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">Client:</h3>
                  <p className="cs-m0">{projectDetails.projectClient}</p>
                  <Spacing lg="30" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      )}
      <Spacing lg="145" md="80" />
      <Cta
        title="Info@Nexlla.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
