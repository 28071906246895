import React, { useEffect, useState } from 'react';
import Hero9 from '../Hero/Hero9';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import IconBoxStyle2 from '../IconBox/IconBoxStyle2';
import PortfolioSlider from '../Slider/PortfolioSlider';
import TestimonialSliderStyle2 from '../Slider/TestimonialSliderStyle2';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { Icon } from '@iconify/react';
import LogoList from '../LogoList';
import GalleryStyle2 from '../Gallery/GalleryStyle2';
import MovingText2 from '../MovingText/MovingText2';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

const serviceData1 = [
  {
    title: 'Conceptual Design',
    href: '',
  },
  {
    title: 'Schematic Design',
    href: '',
  },
  {
    title: 'Design Development',
    href: '',
  },
  {
    title: 'Construction Documents',
    href: '',
  },
  {
    title: 'Construction Administration',
    href: '',
  },
];
const serviceData2 = [
  {
    title: 'Feasibility Studies',
    href: '',
  },
  {
    title: '3D Visualization & Rendering',
    href: '',
  },
  {
    title: 'Sustainability Consulting',
    href: '',
  },
  {
    title: 'Interior Design',
    href: '',
  },
  {
    title: 'Building Information Modeling (BIM)',
    href: '',
  },
];

export default function ArchitectureServices() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    projectType: '',
    mobile: '',
    message: '',
  });
  const [submitStatus, setSubmitStatus] = useState('Send Message');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitStatus('Submitting...');

    try {
      const response = await fetch('https://formspree.io/f/mgegnqqr', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitStatus('Submitted successfully!');
        setFormData({
          fullName: '',
          email: '',
          projectType: '',
          mobile: '',
          message: '',
        });
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error(error);
      setSubmitStatus('Error submitting form, please try again.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Simulate a data fetch for the page
    const fetchData = async () => {
      try {
        // Simulating a fetch call
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoading(false);
      } catch (error) {
        setError('An error occurred while fetching data.');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Architecture Design | Nexlla Creative Agency</title>
        <meta name="description" content="Explore Nexlla Creative Agency's architecture design services, including conceptual design, schematic design, and more." />
        <meta name="keywords" content="Nexlla architecture design, architectural design services, building design, interior design, sustainable architecture, green building design, space planning, building information modeling, BIM services, 3D modeling, architectural visualization, rendering services, architectural animation, virtual reality architecture, augmented reality architecture, architectural conceptualization, schematic design, design development, construction documentation, architectural drafting, CAD services, architectural project management, site analysis, feasibility studies, building code compliance, accessibility design, universal design, residential architecture, commercial architecture, industrial architecture, institutional architecture, hospitality architecture, retail architecture, mixed-use architecture, urban planning, master planning, landscape architecture, interior space design, furniture selection, material selection, lighting design, acoustic design, signage and wayfinding, branding integration, LEED certification, energy-efficient design, passive design strategies, biophilic design, parametric design, generative design, computational design, digital fabrication, 3D printing, prefabrication, modular construction, renovation and remodeling, historic preservation, adaptive reuse, building envelope design, facade design, roofing design, structural design collaboration, MEP coordination, sustainability consulting, post-occupancy evaluation" />
        <link rel="canonical" href="https://nexlla.com/service/architecture-design" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Service",
            "name": "Architecture Design",
            "description": "Explore Nexlla Creative Agency's architecture design services, including conceptual design, schematic design, and more.",
            "url": "https://nexlla.com/ArchitectureService"
          }
          `}
        </script>
      </Helmet>

      <Hero9
        title="Next-Generation Architecture"
        subtitle="Bring your architectural vision to life with ease and exceed client expectations using our collaborative platform designed to elevate your work and deliver exceptional results."
        btnLink="/service/architecture-design"
        btnText="Our Steps"
        bgImageUrl="images/hero_bg_6.jpeg"
        imgUrl="images/hero_img_1.png"
        spiningCircleUrl="images/hero_img_6.svg"
        videoSrc="https://www.youtube.com/watch?v=qr8Iydr6XAs"
      />
      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="At Nexlla, Your Vision is our Mission."
              subtitle="About Nexlla Architecture Design"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                We believe exceptional design is a collaborative journey, and we're committed to providing the technology and support to bring your best work to fruition. Our advanced platform isn't just about software, it's about enabling you to achieve stunning, functional designs that exceed your clients' expectations.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_6.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_7.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_8.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}
      {/* Start Service Section */}
      <section className="cs-shape_wrap_4 cs-parallax">
        <div className="cs-shape_4 cs-to_up" />
        <div className="cs-shape_4 cs-to_right" />
        <div className="cs-height_50 cs-height_lg_50" />
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="We Provide Best Value Offer"
                subtitle="Services"
                btnLink="/service/architecture-design"
                btnText="Including Services"
              />
              <Spacing lg="45" md="45" />
            </div>
            <div className="col-lg-7 offset-xl-1">
              <div className="cs-iconbox_4_wrap">
                <IconBoxStyle2
                  title="Conceptual Design"
                  subTitle="Translating client needs into initial sketches, 3D models, and visualizations."
                  iconUrl="images/icons/service_icon_4.svg"
                  btnLink="/service/architecture-design"
                  btnText="Learn More"
                />
                <IconBoxStyle2
                  title="Schematic Design"
                  subTitle="Developing floor plans, elevations, and refined concepts."
                  iconUrl="images/icons/service_icon_5.svg"
                  btnLink="/service/architecture-design"
                  btnText="Learn More"
                />
                <IconBoxStyle2
                  title="Design Development"
                  subTitle="Detailed technical drawings, material selection, and coordination with engineers."
                  iconUrl="images/icons/service_icon_6.svg"
                  btnLink="/service/architecture-design"
                  btnText="Learn More"
                />
                <IconBoxStyle2
                  title="Construction Documents"
                  subTitle="Producing final drawings and specifications for permits and construction."
                  iconUrl="images/icons/service_icon_7.svg"
                  btnLink="/service/architecture-design"
                  btnText="Learn More"
                />
                <IconBoxStyle2
                  title="Construction Administration"
                  subTitle="Site visits, progress monitoring, addressing contractor questions, and ensuring design execution aligns with plans."
                  iconUrl="images/icons/service_icon_8.svg"
                  btnLink="/service/architecture-design"
                  btnText="Learn More"
                />
                <IconBoxStyle2
                  title="3D Visualization & VR"
                  subTitle="High-quality photorealistic renderings and walkthroughs for client presentations or marketing, With VR Immersive design experiences."
                  iconUrl="images/icons/service_icon_9.svg"
                  btnLink="/service/architecture-design"
                  btnText="Learn More"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cs-height_145 cs-height_lg_75" />
      </section>
      {/* End Service Section */}
      
      {/* Start Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Portfolio To Explore"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        {/* Pass an array of categories */}
        <PortfolioSlider categories={['Architecture Design']} />
      </Div>
      {/* End Portfolio Section */}

      {/* Start Moving Text Section */}
      <Spacing lg="145" md="80" />
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}
      <Spacing lg="170" md="80" />
      {/* Start Testimonial Section */}
      <TestimonialSliderStyle2 />
      {/* End Testimonial Section */}
      {/* Start Contact Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form className="row" onSubmit={handleSubmit}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" className="cs-form_field" name="fullName" value={formData.fullName} onChange={handleChange} required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="email" className="cs-form_field" name="email" value={formData.email} onChange={handleChange} required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" className="cs-form_field" name="projectType" value={formData.projectType} onChange={handleChange} required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="tel" className="cs-form_field" name="mobile" value={formData.mobile} onChange={handleChange} required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Your Message*</label>
                <textarea name="message" value={formData.message} onChange={handleChange} cols="30" rows="7" className="cs-form_field" required></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1">
                  {submitStatus}
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="125" md="55" />
      {/* End Contact Section */}
      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="130" md="70" />
      {/* End LogoList Section */}
      {/* Start Gallery Section */}
      <GalleryStyle2 />
      {/* End Gallery Section */}
    </>
  );
}
