import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';

export default function TestimonialSliderStyle2() {
    const [testimonials, setTestimonials] = useState([]);
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/testimonials/testimonials`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setTestimonials(data.data.testimonials); // Make sure this path matches your API response structure
            } catch (error) {
                console.error('Error fetching testimonials:', error);
            }
        };

        fetchTestimonials();
    }, []);

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <Div
            {...props}
            className={'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')}
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
        >
            <Icon icon="bi:arrow-left" />
        </Div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <Div
            {...props}
            className={'slick-next slick-arrow' + (currentSlide === slideCount - 1 ? ' slick-disabled' : '')}
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
        >
            <Icon icon="bi:arrow-right" />
        </Div>
    );

    return (
        <>
            <Div className="cs-shape_wrap_3 cs-parallax">
                <Div
                    className="cs-bg"
                    style={{ backgroundImage: `url(images/testimonial_bg.jpeg)` }}
                >
                    <Spacing lg="130" md="80" />
                    <Div className="cs-shape_3 cs-to_up">
                        <img src="/images/shape_1.svg" alt="Shape" />
                    </Div>
                    <Div className="container">
                        <Div className="cs-testimonial_slider_2">
                            <Div className="cs-testimonial_slider_left">
                                <Slider
                                    asNavFor={nav1}
                                    ref={slider2 => setNav2(slider2)}
                                    slidesToShow={3}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    centerMode={true}
                                    centerPadding="0px"
                                    arrows={false}
                                >
                                    {testimonials.map((item, index) => (
                                        <Div className="slider-nav_item" key={index}>
                                            <Div className="cs-rotate_img">
                                                <Div className="cs-rotate_img_in">
                                                    <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.image}`} alt="Thumb" />
                                                </Div>
                                            </Div>
                                        </Div>
                                    ))}
                                </Slider>
                            </Div>
                            <Slider
                                asNavFor={nav2}
                                ref={slider1 => setNav1(slider1)}
                                prevArrow={<SlickArrowLeft />}
                                nextArrow={<SlickArrowRight />}
                                className="cs-arrow_style1"
                            >
                                {testimonials.map((item, index) => (
                                    <Div key={index}>
                                        <Testimonial
                                            testimonialText={item.quote}
                                            avatarName={item.name}
                                            avatarDesignation={item.positionAndCompany}
                                            ratings={item.stars.toString()}
                                        />
                                    </Div>
                                ))}
                            </Slider>
                        </Div>
                    </Div>
                    <Spacing lg="130" md="80" />
                </Div>
            </Div>
        </>
    );
}
