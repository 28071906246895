import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Post from '../Post';

export default function PostSlider() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const postData = await response.json();
        
        // Sort the posts from newest to oldest based on publishedAt timestamp
        const sortedPosts = postData.data.articles.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
        
        // Limit to the last 5 posts
        setPosts(sortedPosts.slice(0, 5));
      } catch (error) {
        console.error('Error fetching recent posts:', error);
        setError('Error fetching recent posts. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentPosts();
  }, []);

  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderPosts = isLoading ? (
    <p>Loading posts...</p>
  ) : error ? (
    <p>Error: {error}</p>
  ) : (
    posts.map((item, index) => (
      <Div key={index}>
        <Post
          url={`/blog/${item.slug}`}
          src={`${process.env.REACT_APP_API_URL}/api/images/${item.imageCover}`}
          alt={item.articleTitle}
          date={new Date(item.publishedAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '.')}
          title={item.articleTitle.length > 35 ? `${item.articleTitle.slice(0, 35)}...` : item.articleTitle}
        />
      </Div>
    ))
  );

  return (
    <Slider {...settings} className="cs-gap-24">
      {renderPosts}
    </Slider>
  );
}
