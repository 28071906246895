import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function PortfolioPage() {
  pageTitle('Portfolio | ');
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(7);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/projects`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Sort projects by publishedAt in descending order (newest first)
        const sortedProjects = data.data.projects.sort((a, b) => b.sortOrder - a.sortOrder);
        setProjects(sortedProjects);

        // Extract unique categories
        const categories = new Set(sortedProjects.map(project => project.projectCategory));
        setUniqueCategories(Array.from(categories));
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Portfolio | Nexlla Creative Agency</title>
        <meta name="description" content="Explore our portfolio showcasing our latest projects and designs at Nexlla Creative Agency." />
        <meta name="keywords" content="Nexlla portfolio, creative agency portfolio, design portfolio, UI/UX design portfolio, web development portfolio, mobile app development portfolio, branding portfolio, graphic design portfolio, logo design portfolio, motion graphics portfolio, video production portfolio, web3 portfolio, blockchain development portfolio, NFT design portfolio, e-commerce portfolio, responsive web design portfolio, front-end development portfolio, back-end development portfolio, full-stack development portfolio, user experience portfolio, user interface portfolio, interaction design portfolio, product design portfolio, packaging design portfolio, print design portfolio, illustration portfolio, photography portfolio, 3D modeling portfolio, animation portfolio, AR/VR portfolio, game development portfolio, software development portfolio, mobile app UI portfolio, mobile app UX portfolio, cross-platform app development portfolio, website redesign portfolio, landing page design portfolio, email template design portfolio, social media design portfolio, infographic design portfolio, data visualization portfolio, editorial design portfolio, typeface design portfolio, icon design portfolio, wireframing portfolio, prototyping portfolio, usability testing portfolio, design system portfolio, style guide portfolio" />
        <link rel="canonical" href="https://nexlla.com/portfolio" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "ItemList",
            "name": "Portfolio",
            "description": "Explore our portfolio showcasing our latest projects and designs at Nexlla Creative Agency.",
            "url": "https://nexlla.com/portfolio",
            "numberOfItems": ${projects.length},
            "itemListElement": ${projects.map((project, index) => `
              {
                "@type": "ListItem",
                "position": ${index + 1},
                "url": "https://nexlla.com/portfolio/${project.slug}",
                "name": "${project.projectTitle}"
              }
            `)}
          }
          `}
        </script>
        
      </Helmet>
      <PageHeading
        title="Our Portfolio"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText="Portfolio"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {/* Render unique categories */}
              {uniqueCategories.map((category, index) => (
                <li
                  className={active === category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(category)}>
                    {category}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {projects.slice(0, itemShow).map((project, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === project.projectCategory)
                  ? 'd-none'
                  : ''
              }`}
              key={index}
            >
              <Portfolio
                title={project.projectTitle}
                subtitle="See Details"
                href={`/portfolio/${project.slug}`}
                src={`${process.env.REACT_APP_API_URL}/api/images/${project.projectCover}`}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {projects.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="Info@Nexlla.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
