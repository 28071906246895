import React, { useEffect, useState } from 'react';
import AuthorWidget from '../Widget/AuthorWidget';
import RecentPost from '../Widget/RecentPost';
import SearchWidget from '../Widget/SearchWidget';
import SideMenuWidget from '../Widget/SideMenuWidget';
import TagWidget from '../Widget/TagWidget';
import Div from '../Div';

export default function Sidebar({ onFilterChange, currentFilter, recentPosts }) {
  const [categoryData, setCategoryData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);

  useEffect(() => {
    const fetchSidebarData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const postData = await response.json();

        // Process categories
        const categoriesMap = new Map();
        postData.data.articles.forEach(article => {
          categoriesMap.set(article.category, `/blog?filter=${encodeURIComponent(article.category)}`);
        });
        const categories = Array.from(categoriesMap).map(([title, url]) => ({ title, url }));

        // Process tags
        const tagsMap = new Map();

        postData.data.articles.forEach(article => {
          article.tags.slice(0, 2).forEach(tag => {
            tagsMap.set(tag, `/blog?filter=${encodeURIComponent(tag)}`);
          });
        });

        const limitedTags = Array.from(tagsMap.entries()).slice(-15);
        const limitedTagsMap = new Map(limitedTags);
        const tags = Array.from(limitedTagsMap).map(([title, url]) => ({ title, url }));

        // Process archives
        const archivesMap = new Map();
        postData.data.articles.forEach(article => {
          const year = new Date(article.publishedAt).getFullYear();
          if (!archivesMap.has(year)) {
            archivesMap.set(year, `/blog?filter=${encodeURIComponent(year)}`);
          }
        });
        const archives = Array.from(archivesMap).map(([year, url]) => ({ title: `Before ${year}`, url }));

        setCategoryData(categories);
        setTagData(tags);
        setArchiveData(archives);
      } catch (error) {
        console.error('Error fetching sidebar data:', error);
      }
    };

    fetchSidebarData();
  }, []);

  const handleItemClick = (url) => {
    const urlParams = new URLSearchParams(url.substring(url.indexOf('?')));
    const filterValue = urlParams.get('filter');
    onFilterChange(filterValue);
  };

  const handleRemoveFilters = () => {
    onFilterChange('');
  };

  const handleSearch = async (searchTerm) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts?search=${encodeURIComponent(searchTerm)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Remove the unused variable
    } catch (error) {
      console.error('Error searching articles:', error);
    }
  };
  

  const isActive = (url) => {
    const urlParams = new URLSearchParams(url.substring(url.indexOf('?')));
    return currentFilter === urlParams.get('filter');
  };

  return (
    <>
      <Div className="cs-sidebar_item">
        <AuthorWidget src='/images/avatar_1.png' name='Ghaith Daghistani' description='Welcome to Nexlla Creative Agency, where we turn bold ideas into digital brilliance. Let’s elevate your brand together.' />
      </Div>
      <Div className="cs-sidebar_item">
        <SearchWidget title='Search' onSearch={handleSearch} />
      </Div>
      {currentFilter && (
        <Div className="cs-sidebar_item">
          <button onClick={handleRemoveFilters} style={{ background: 'none', border: 'none', padding: '0px', cursor: 'pointer', color: '#DDDDDD', textDecoration: 'underline' }}>
            Remove Filters
          </button>
        </Div>
      )}
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Categories' data={categoryData} onItemClick={handleItemClick} isActive={isActive} />
      </Div>
      <Div className="cs-sidebar_item">
        <RecentPost title="Recent Posts" data={recentPosts} onItemClick={handleItemClick} />
      </Div>
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Archives' data={archiveData} onItemClick={(item) => handleItemClick(item.url)} />
      </Div>
      <Div className="cs-sidebar_item">
        <TagWidget title='Tags' data={tagData} onItemClick={(item) => handleItemClick} currentFilter={currentFilter} />
      </Div>
    </>
  );
}
