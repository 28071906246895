import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import Cta from '../Cta';
import IconBox from '../IconBox';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';

export default function ServiceDetailsPage() {
  const { slug } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setServiceData(data.data.service);
      } catch (error) {
        console.error('Error fetching service data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchServiceData();
  }, [slug]);

  useEffect(() => {
    if (serviceData) {
      pageTitle(`${serviceData.serviceName} | `);
    }
  }, [serviceData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {serviceData && (
        <Helmet>
          <title>{`${serviceData.serviceName} | Nexlla Creative Agency`}</title>
          <meta
            name="description"
            content={serviceData.serviceDescription || 'Discover our detailed services and how we can help you.'}
          />
          <meta name="keywords" content={serviceData.keywords?.join(', ') || 'Nexlla, Services, Creative Agency'} />
          <link rel="canonical" href={`https://nexlla.com/service/${slug}`} />
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "${serviceData.serviceName}",
              "description": "${serviceData.serviceDescription}",
              "url": "https://nexlla.com/service/${slug}"
            }
            `}
          </script>
        </Helmet>
      )}
      <PageHeading
        title={serviceData ? serviceData.serviceName : 'Service Details'}
        bgSrc={`${process.env.REACT_APP_API_URL}/api/images/${serviceData?.servicePageCover}`}
        pageLinkText={serviceData?.serviceName}
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        {serviceData && (
          <>
            <SectionHeading
              title={serviceData.serviceTitle}
              subtitle={serviceData.serviceSubtitle}
              variant="cs-style1 text-center"
            />
            <Spacing lg="90" md="45" />
            <Div className="row">
              <Div className="col-lg-4">
                <IconBox
                  icon={`${process.env.REACT_APP_API_URL}/api/images/${serviceData.stepOneName.image}`}
                  title={serviceData.stepOneName.title}
                  subtitle={serviceData.stepOneName.subtitle}
                />
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-lg-4">
                <IconBox
                  icon={`${process.env.REACT_APP_API_URL}/api/images/${serviceData.stepTwoName.image}`}
                  title={serviceData.stepTwoName.title}
                  subtitle={serviceData.stepTwoName.subtitle}
                />
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-lg-4">
                <IconBox
                  icon={`${process.env.REACT_APP_API_URL}/api/images/${serviceData.stepThreeName.image}`}
                  title={serviceData.stepThreeName.title}
                  subtitle={serviceData.stepThreeName.subtitle}
                />
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </>
        )}
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        {serviceData && (
          <Div className="row align-items-center">
            <Div className="col-xl-5 col-lg-6">
              <Div className="cs-radius_15 cs-shine_hover_1">
                <img
                  src={`${process.env.REACT_APP_API_URL}/api/images/${serviceData.serviceImage}`}
                  alt="Service"
                  className="cs-radius_15 w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <h2 className="cs-font_50 cs-m0">{serviceData.serviceIncludeTitle}</h2>
              <Spacing lg="50" md="30" />
              <Div className="row">
                <Div className="col-lg-6">
                  <Button btnLink="" btnText={serviceData.textNo1} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  <Button btnLink="" btnText={serviceData.textNo2} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  <Button btnLink="" btnText={serviceData.textNo3} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  <Button btnLink="" btnText={serviceData.textNo4} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  <Button btnLink="" btnText={serviceData.textNo5} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  <Button btnLink="" btnText={serviceData.textNo6} variant="cs-type2" />
                  <Spacing lg="0" md="10" />
                </Div>
                <Div className="col-lg-6">
                  <Button btnLink="" btnText={serviceData.textNo7} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  <Button btnLink="" btnText={serviceData.textNo8} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  <Button btnLink="" btnText={serviceData.textNo9} variant="cs-type2" />
                  <Spacing lg="20" md="10" />
                  {serviceData.textNo10 && (
                    <>
                      <Button btnLink="" btnText={serviceData.textNo10} variant="cs-type2" />
                      <Spacing lg="20" md="10" />
                    </>
                  )}
                  {serviceData.textNo11 && (
                    <>
                      <Button btnLink="" btnText={serviceData.textNo11} variant="cs-type2" />
                      <Spacing lg="20" md="10" />
                    </>
                  )}
                  {serviceData.textNo12 && (
                    <>
                      <Button btnLink="" btnText={serviceData.textNo12} variant="cs-type2" />
                      <Spacing lg="20" md="10" />
                    </>
                  )}
                </Div>
              </Div>
            </Div>
          </Div>
        )}
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <Spacing lg="145" md="80" />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading title="Some pre questions and answers" subtitle="FAQ's" />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let's Discuss Making <br>Something <i>Cool</i> Together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
