import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import Sidebar from '../Sidebar.jsx';
import Spacing from '../Spacing';
import { Helmet } from 'react-helmet';
import { Icon } from '@iconify/react';
import { CircleLoader } from 'react-spinners';

export default function BlogDetailsPage() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    comment: ''
  });

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPost(data.data.article);
      } catch (error) {
        console.error('Error fetching post:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchComments = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/comments/posts/${slug}/comments`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setComments(data.data.comments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchPost();
    fetchComments();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
    pageTitle(`${post?.articleTitle || ''} | `);
  }, [post]);

  useEffect(() => {
    // Set keywords for SEO
    const keywords = post?.keywords?.join(', ');
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute('content', keywords || '');
    }
  }, [post]);

  const renderContentWithLinksAndImages = (content) => {
    // Regular expression to identify links and images in content
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
    const imgRegex = /<img\s+(?:[^>]*?\s+)?src="([^"]*)"/g;

    // Replace links to open in a new tab
    const contentWithLinks = content.replace(
      linkRegex,
      '<a style="text-decoration: underline;" href="$1" target="_blank" rel="noopener noreferrer"'
    );

    // Replace image sources with correct API URL and add margin and border-radius styles
    const contentWithImages = contentWithLinks.replace(
      imgRegex,
      (match, src) => {
        const fullSrc = src.startsWith('http') || src.startsWith(`${process.env.REACT_APP_API_URL}`) ? src : `${process.env.REACT_APP_API_URL}/api${src}`;
        return `<img src="${fullSrc}" style="margin-top: 20px; margin-bottom: 20px; border-radius: 15px;"`;
      }
    );

    return contentWithImages;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/comments/posts/${slug}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const newComment = await response.json();
      setComments([...comments, newComment.data.comment]);
      setFormData({ fullName: '', email: '', comment: '' });
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircleLoader color="#be4602" size={80} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!post) {
    return <div>Post not found.</div>;
  }

  return (
    <>
      <Helmet>
        <title>{post ? `${post.articleTitle} | Nexlla Creative Agency` : 'Blog | Nexlla Creative Agency'}</title>
        <meta name="description" content={post ? post.articleContent.substring(0, 150) : 'Read the latest articles and insights from Nexlla Creative Agency.'} />
        <meta name="keywords" content={post?.keywords?.join(', ')} />
        <link rel="canonical" href={`https://nexlla.com/blog/${slug}`} />
        {post && (
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              "headline": "${post.articleTitle}",
              "description": "${post.articleContent.substring(0, 150)}",
              "url": "https://nexlla.com/blog/${slug}",
              "datePublished": "${post.publishedAt}",
              "author": {
                "@type": "Person",
                "name": "${post.author}"
              },
              "image": "${process.env.REACT_APP_API_URL}/api/images/${post.imageCover}"
            }
            `}
          </script>
        )}
      </Helmet>

      {/* Start Page Heading Section */}
      <PageHeading
        title="Read The Full Blog"
        bgSrc="/images/blog_details_hero_bg.jpeg"
        pageLinkText={post.articleTitle}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">
            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15" style={{ marginBottom: '20px' }}>
                <img
                  src={`${process.env.REACT_APP_API_URL}/api/images/${post.imageCover}`}
                  alt="Post"
                  className="w-100 cs-radius_15"
                />
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">
                    {new Date(post.publishedAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '.')}
                  </span>
                  <Link
                    to={`/blog?filter=${post.category}`}
                    className="cs-post_avatar"
                  >
                    {post.category}
                  </Link>
                </Div>
                <h2 className="cs-post_title">{post.articleTitle}</h2>
                <div dangerouslySetInnerHTML={{ __html: renderContentWithLinksAndImages(post.articleContent) }} />
              </Div>
            </Div>
            {/* End Details Post Content */}

            {/* Display Comments Section */}
            {comments.length > 0 && (
              <>
                <Spacing lg="50" md="30" />
                <h2 className="cs-font_50 cs-m0 mb-4">Comments</h2>
                <Spacing lg="5" md="5" />
                <Div className="cs-comments">
                  {comments.map((comment, index) => (
                    <Div key={index} className="cs-comment" style={{ marginBottom: '20px' }}>
                      <Div className="cs-comment_info" style={{ paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
                        <Div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                          <h6 className="cs-comment_author" style={{ fontSize: '14px', color: '#DDDDDD', margin: 0 }}>{comment.fullName}</h6>
                          <span className="cs-comment_date" style={{ fontSize: '12px', color: '#999', marginLeft: '10px' }}>{new Date(comment.createdAt).toLocaleDateString()}</span>
                        </Div>
                        <p style={{ marginBottom: '10px', fontSize: '16px' }}>{comment.comment}</p>
                      </Div>
                    </Div>
                  ))}
                </Div>
              </>
            )}
            {/* End Display Comments Section */}

            {/* Start Comment Section */}
            <Spacing lg="30" md="30" />
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg="5" md="5" />
            <p className="cs-m0">Your email address will not be published.</p>
            <Spacing lg="40" md="30" />
            <form className="row" onSubmit={handleSubmit}>
              <Div className="col-lg-6">
                <label>Full Name*</label>
                <input type="text" className="cs-form_field" name="fullName" value={formData.fullName} onChange={handleChange} required />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input type="email" className="cs-form_field" name="email" value={formData.email} onChange={handleChange} required />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea cols={30} rows={7} className="cs-form_field" name="comment" value={formData.comment} onChange={handleChange} required />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button type="submit" className="cs-btn cs-style1">
                  <span>Add Comment</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            {/* End Comment Section */}
            
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            {/* Start Sidebar */}
            <Spacing lg="0" md="80" />
            <Sidebar />
            {/* End Sidebar */}
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let's Discuss Making <br>Something <i>Cool</i> Together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
