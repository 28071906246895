import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget({ behance, linkedin, instagram, twitter }) {
  return (
    <Div className="cs-social_btns cs-style1">
      {/* Render Behance icon */}
      {behance && (
        <Link to={behance} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:behance" />
        </Link>
      )}

      {/* Render LinkedIn icon */}
      {linkedin && (
        <Link to={linkedin} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:linkedin-in" />
        </Link>
      )}

      {/* Render Instagram icon */}
      {instagram && (
        <Link to={instagram} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:instagram" />
        </Link>
      )}

      {/* Render Twitter icon */}
      {twitter && (
        <Link to={twitter} className="cs-center" target="_blank" rel="noopener noreferrer">
          <Icon icon="fa6-brands:twitter" />
        </Link>
      )}
    </Div>
  );
}